import iconv from 'iconv-lite'

import { IData, KBankFundsTransferProductType, TaxType } from '../types'

const PAYMENT_TYPE = KBankFundsTransferProductType.DCT
const HEADER_PART_IDENTIFIER = 'H'
const DETAIL_PART_IDENTIFIER = 'D'
const TAX_PART_IDENTIFIER = 'T'
const MESSAGE_PART_IDENTIFIER = 'M'
const WITHHOLDING_TAX = '1'
const PND_3 = '05'
const PND_53 = '07'
const ADVICE_MODE = 'E'

export function generateKBankAccountFundsTransferTextFile(
  effectiveDate: Date,
  dataList: IData[],
  debitAccountNumber: string,
  chargesAccount: string,
) {
  const effectiveDateServer = new Date(+effectiveDate)
  const currentDateServer = new Date()

  let content = ''
  const totalDebitAmount = dataList.reduce(
    (totalAmount, thisDebitAmount) =>
      totalAmount + (thisDebitAmount.netAmount || 0),
    0,
  )

  // #region Header
  content += `${HEADER_PART_IDENTIFIER}${PAYMENT_TYPE}`
  content += ''.padEnd(16, ' ') // Batch Ref.#
  content += ''.padEnd(6, '0') // Trans-No
  content += ''.padEnd(14, ' ') // Filler
  content += debitAccountNumber
  content += ''.padEnd(1, ' ') // Filler
  content += getNumber(totalDebitAmount, 15) // Total Debit Amount
  content += ''.padEnd(1, ' ') // Filler
  content += getDate(currentDateServer) // Transaction Date
  content += ''.padEnd(25, ' ') // Filler
  content += 'บริษัท บับเบิลลี จำกัด'.padEnd(50, ' ') // Name
  content += getDate(effectiveDateServer) // Effective Date
  content += `${dataList.length}`.padStart(18, '0') // Total Credit Items
  content += chargesAccount
  content += ''.padEnd(5, ' ') // Customer Branch #
  content += '\n'
  // #endregion

  // #region Details
  dataList.forEach((data, index) => {
    const {
      vat,
      taxPercent,
      amount,
      netAmount,
      bankAccountId,
      bankAccountName,
      email,
      mobileNumber,
      taxId,
    } = data
    const taxType = taxPercent ? TaxType.Other : TaxType.Fee
    const tax = taxType === TaxType.Other ? (amount * taxPercent) / 100 : 0
    const isCompany = taxPercent ? true : false //  ดูว่ามีภาษีคิดเป็นเปอร์เซ็นต์มั้ย

    let taxSequence = 1
    const taxInfoCount = taxType === TaxType.Other ? '001' : '000'
    let detail = ''
    let messageInformation = ''

    detail += DETAIL_PART_IDENTIFIER // Part Identifier
    detail += `${index + 1}`.padStart(6, '0') // Trans-No
    detail += ''.padEnd(14, ' ') // Filler
    detail += bankAccountId // Payee A/C No.
    detail += ''.padEnd(1, ' ') // Filler
    detail += getNumber(netAmount, 15) // Filler
    detail += ''.padEnd(1, ' ') // Filler
    detail += getDate(currentDateServer) // Transaction Date
    detail += ''.padEnd(25, ' ') // Filler
    detail += bankAccountName.slice(0, 50).padEnd(50, ' ') // Payee
    detail += getDate(effectiveDateServer) // Effective Date
    detail += taxInfoCount.padStart(3, ' ') // Tax Info Count
    detail += `BeneRef${`${index}`.padStart(3, '0')}`.padEnd(16, ' ') // Bene. Ref #
    detail += ''.padStart(50, ' ') // Attachment Sub-file
    detail += ADVICE_MODE.padStart(1, ' ') // Advice Mode
    detail += ''.padStart(50, ' ') // Fax No.
    detail += email.padStart(50, ' ') // Email ID
    detail += amount.toFixed(2).padStart(10 + 1 + 2, ' ') // Total Inv Amt bef VAT
    detail += tax.toFixed(2).padStart(10 + 1 + 2, ' ') // Total Tax deducted Amt
    detail += (amount + (vat || 0)).toFixed(2).padStart(10 + 1 + 2, ' ') //  Total Inv Amt after VAT
    detail += ''.padEnd(10, ' ') // Filler
    detail += taxId.padStart(13, ' ') // Tax Id / Personal Id
    detail += ''.padStart(30, ' ') // Payee Address 1
    detail += ''.padStart(30, ' ') // Payee Address 2
    detail += ''.padStart(30, ' ') // Payee Address 3
    detail += ''.padStart(30, ' ') // Payee Address 4

    content += detail
    content += '\n'

    if (taxType === TaxType.Other) {
      let taxInformation = ''
      taxInformation += TAX_PART_IDENTIFIER
      taxInformation += isCompany ? PND_53 : PND_3
      taxInformation += `${taxSequence}`.padEnd(10, ' ') // Sequence of Withholding Tax.
      taxInformation += taxPercent.toFixed(2).padStart(3 + 1 + 2, '0') // Tax rate
      taxInformation += '021'.padEnd(40, ' ') // Type of tax deducted
      taxInformation += amount.toFixed(2).padStart(10 + 1 + 2, '0') // Inv Amt bef VAT
      taxInformation += tax.toFixed(2).padStart(10 + 1 + 2, '0') // Tax deducted Amt
      taxInformation += (amount + (vat || 0))
        .toFixed(2)
        .padStart(10 + 1 + 2, '0') // Inv Amt after VAT
      taxInformation += WITHHOLDING_TAX // Tax Condition

      content += taxInformation
      content += '\n'
      taxSequence += 1
    }

    messageInformation += MESSAGE_PART_IDENTIFIER
    messageInformation += mobileNumber.padEnd(54, ' ')
    messageInformation += 'TH'
    content += messageInformation
    content += '\n'
  })
  // #endregion

  const encodedContent = iconv.encode(content, 'TIS-620')
  const encodedContentNumberArrays: number[] = []

  encodedContent.forEach((integers) =>
    encodedContentNumberArrays.push(integers),
  )

  const fileName = `${PAYMENT_TYPE}_${getDate(effectiveDate)}_buddy.txt`

  return { content: encodedContentNumberArrays, fileName }
}

function getDate(date: Date) {
  const year = date.getFullYear().toString().slice(2)
  const month = String(date.getMonth() + 1).padStart(2, '0') // Adding 1 to the month because it's 0-based.
  const dateDay = String(date.getDate()).padStart(2, '0')

  return `${year}${month}${dateDay}`
}

function getNumber(amount: number, pad: number) {
  return `${amount.toFixed(2).replace(/\D/g, '')}`.padStart(pad, '0')
}
