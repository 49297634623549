import iconv from 'iconv-lite'

import { IData, KBankFundsTransferProductType, TaxType } from '../types'

const HEADER_PART_IDENTIFIER = 'H'
export const DETAIL_PART_IDENTIFIER = 'D'
const TAX_PART_IDENTIFIER = 'T'
const MESSAGE_PART_IDENTIFIER = 'M'
const WITHHOLDING_TAX = '1'
const PND_3 = '05'
const PND_53 = '07'
const ADVICE_MODE = 'E'

export function generateOtherBankFundsTransferTextFile(
  effectiveDate: Date,
  dataList: IData[],
  debitAccountNumber: string,
  chargesAccount: string,
  paymentType: KBankFundsTransferProductType,
) {
  const effectiveDateServer = new Date(+effectiveDate)
  let content = ''
  const effectiveDateYear = effectiveDateServer.getFullYear()
  const effectiveDateMonth = String(
    effectiveDateServer.getMonth() + 1,
  ).padStart(2, '0') // Adding 1 to the month because it's 0-based.
  const effectiveDateDay = String(effectiveDateServer.getDate()).padStart(
    2,
    '0',
  )
  const totalDebitAmount = dataList.reduce(
    (totalAmount, thisDebitAmount) =>
      totalAmount + (thisDebitAmount.netAmount || 0),
    0,
  )
  // #region Header
  content += `${HEADER_PART_IDENTIFIER}${paymentType}${debitAccountNumber}`
  content += ''.padEnd(16, ' ') // Batch Ref.#
  content += `${effectiveDateDay}-${effectiveDateMonth}-${effectiveDateYear}` // Effective Date
  content += ''.padEnd(5, ' ') // Customer Branch #
  content += `${dataList.length}`.padStart(18, '0') // Total Credit Items
  content += `${totalDebitAmount.toFixed(2)}`.padStart(15 + 1 + 2, '0') // Total Debit Amount
  content += chargesAccount
  content += '\n'
  // #endregion

  // #region Details
  dataList.forEach((data, index) => {
    const {
      vat,
      taxPercent,
      amount,
      netAmount,
      bankAccountId,
      bankAccountName,
      email,
      mobileNumber,
      taxId,
      bankBranch,
      bankCode,
    } = data
    const taxType = taxPercent ? TaxType.Other : TaxType.Fee
    const tax = taxType === TaxType.Other ? (amount * taxPercent) / 100 : 0
    const isCompany = taxPercent ? true : false //  ดูว่ามีภาษีคิดเป็นเปอร์เซ็นต์มั้ย

    let taxSequence = 1
    const taxInfoCount = taxType === TaxType.Other ? '001' : '000'
    let detail = ''
    let messageInformation = ''

    detail += DETAIL_PART_IDENTIFIER // Part Identifier
    detail += 'BRR' + `${index + 1}`.padStart(7, '0') // Txn. Reference no.
    detail += netAmount.toFixed(2).padStart(10 + 1 + 2, '0') // Amount
    detail += bankAccountName.slice(0, 80).padEnd(80, ' ') // Payee
    detail += ''.padStart(30, ' ') // Payee Address 1
    detail += ''.padStart(30, ' ') // Payee Address 2
    detail += ''.padStart(30, ' ') // Payee Address 3
    detail += ''.padStart(30, ' ') // Payee Address 4
    detail +=
      paymentType === KBankFundsTransferProductType.IPP
        ? bankAccountId.padEnd(20, ' ')
        : bankAccountId.padStart(20, '0') // A/C #
    detail += `BeneRef${`${index}`.padStart(3, '0')}`.padEnd(16, ' ') // Bene. Ref #
    detail += taxId.padStart(13, ' ') // Personal Id
    detail += bankBranch.padEnd(4, ' ') // Branch Code
    detail += bankCode.padStart(3, ' ') // Bank No
    detail += ''.padStart(255, ' ') // Details
    detail += ''.padStart(10, ' ') // Filler
    detail += ''.padStart(50, ' ') // Attachment Sub-file
    detail += ADVICE_MODE.padStart(1, ' ') // Advice Mode
    detail += ''.padStart(50, ' ') // Fax No.
    detail += email.padStart(50, ' ') // Email ID
    detail += amount.toFixed(2).padStart(10 + 1 + 2, ' ') // Total Inv Amt bef VAT
    detail += tax.toFixed(2).padStart(10 + 1 + 2, ' ') // Total Tax deducted Amt
    detail += (amount + (vat || 0)).toFixed(2).padStart(10 + 1 + 2, ' ') //  Total Inv Amt after VAT
    detail += taxInfoCount.padStart(3, ' ') // Tax Info Count

    content += detail
    content += '\n'

    if (taxType === TaxType.Other) {
      let taxInformation = ''
      taxInformation += TAX_PART_IDENTIFIER
      taxInformation += isCompany ? PND_53 : PND_3
      taxInformation += `${taxSequence}`.padEnd(10, ' ') // Sequence of Withholding Tax.
      taxInformation += taxPercent.toFixed(2).padStart(3 + 1 + 2, '0') // Tax rate
      taxInformation += '021'.padEnd(40, ' ') // Type of tax deducted
      taxInformation += amount.toFixed(2).padStart(10 + 1 + 2, '0') // Inv Amt bef VAT
      taxInformation += tax.toFixed(2).padStart(10 + 1 + 2, '0') // Tax deducted Amt
      taxInformation += (amount + (vat || 0))
        .toFixed(2)
        .padStart(10 + 1 + 2, '0') // Inv Amt after VAT
      taxInformation += WITHHOLDING_TAX // Tax Condition

      content += taxInformation
      content += '\n'
      taxSequence += 1
    }

    messageInformation += MESSAGE_PART_IDENTIFIER
    messageInformation += mobileNumber.padEnd(54, ' ')
    messageInformation += 'TH'
    content += messageInformation
    content += '\n'
  })

  // #endregion
  const encodedContent = iconv.encode(content, 'TIS-620')
  const encodedContentNumberArrays: number[] = []

  encodedContent.forEach((integers) =>
    encodedContentNumberArrays.push(integers),
  )

  const fileName = `${paymentType}_${effectiveDateDay}${effectiveDateMonth}${effectiveDateYear}_buddy.txt`

  return { content: encodedContentNumberArrays, fileName }
}
